

















import { Getter } from "vuex-class";
import { Component, Vue } from "vue-property-decorator";

@Component({
  components: {
    WorkspaceCard: () => import("@/components/workspaces/card.vue"),
    ModalWorkspaceCreate: () => import("@/components/workspaces/modals/create.vue"),
  },
})
export default class PageAdminWorkspaces extends Vue {
  @Getter("workspace/all") workspaces!: Workspace[];

  open = false;
}
